@tailwind base;

@tailwind components;

@tailwind utilities;

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.displayOnParentHover {
  display: none;
}

*:hover > .displayOnParentHover {
  display: block;
}
